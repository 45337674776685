import {
  Grid,
  Container
} from '@material-ui/core';
import {
  BodyTemperatureCard,
  HeartRateCard,
  SpO2Card,
  ECGCard
} from '../components/Card.js';
import styled from 'styled-components';


const RootContainer = styled(Container)`
  padding-top: 1em;
`;

export const Dashboard = () => {
  return (
    <RootContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <BodyTemperatureCard />
        </Grid>
        <Grid item xs={12} sm={4}>
          <HeartRateCard />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SpO2Card />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ECGCard />
        </Grid>
      </Grid>
    </RootContainer >
  );
}