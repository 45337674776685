import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';


export const TemporaryDrawer = (props) => {
  const [open, setOpen] = React.useState(false);

  const cloesDrawer = () => {
    setOpen(false);
  }

  return (
    <div>
      <React.Fragment>
        <Drawer onClose={cloesDrawer} open={open}>
          {/* <Drawer> */}
          <List>
            <ListItem>
              <ListItemText>
                Dashboard
              </ListItemText>
            </ListItem>
          </List>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
