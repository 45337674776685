import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { LinearGauge } from './LinearGauge.js';
import { LineChart } from './LineChart.js';


export const BodyTemperatureCard = () => {
  return (
    <Card>
      <CardContent>
        <h3>
          Body Temperature
        </h3>
        <LinearGauge />
      </CardContent>
    </Card>
  );
}

export const HeartRateCard = () => {
  return (
    <Card>
      <CardContent>
        <h3>
          Heart Rate
        </h3>
        <LinearGauge />
      </CardContent>
    </Card>
  );
}

export const SpO2Card = () => {
  return (
    <Card>
      <CardContent>
        <h3>
          SpO2
      </h3>
        <LinearGauge />
      </CardContent>
    </Card>
  );
}

export const ECGCard = () => {
  return (
    <Card>
      <CardContent>
        <h3>
          ECG
        </h3>
        <LineChart />
      </CardContent>
    </Card>
  );
}
