import {
  Grid,
  Container,
  Paper,
  TextField,
  Button
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';


const RootContainer = styled(Container)`
  padding-top: 1em;  
`;

export const SignIn = () => {
  return (
    <RootContainer>
      <Grid
        container
        direction='column'
        alignItems="center"
      >
        <Paper style={{ width: '20em', padding: 20 }}>
          <Grid
            container
            direction='column'
            spacing={2}
          >
            <Grid item>
              <h3 >
                Sign In
              </h3>
            </Grid>
            <Grid item>
              <TextField
                fullWidth={true}
                id="outlined-basic"
                label="ID"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth={true}
                label="Password"
                type="password"
                autoComplete="current-password"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <Button
                component={Link} to={'/'}
                fullWidth={true}
                variant="contained"
                color="secondary"
              >
                Sign In
            </Button>
            </Grid>
            <Grid item>
              <Button
                component={Link} to={'/signUp'}
                fullWidth={true}
                variant="outlined"
                color="secondary"
              >
                Sign Up
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </RootContainer >
  );
}