import React from 'react';
import { MenuButton, AccountButton } from './Button.js';
import { TemporaryDrawer } from './TemporaryDrawer.js';
import {
  AppBar,
  Grid
} from '@material-ui/core';


export const CustomAppBar = () => {
  return (
    <AppBar position="static">
      <Grid container justify="space-between">
        <Grid item xl={1}>
          <MenuButton />
          <TemporaryDrawer />
        </Grid>
        <Grid item xl={10} />
        <Grid item xl={1}>
          <AccountButton />
        </Grid>
      </Grid>
    </AppBar >
  );
}
