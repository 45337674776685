import './App.css';
import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";
import { CustomAppBar } from './components/AppBar.js'
import { SignIn } from './containers/SignIn'
import { Dashboard } from './containers/Dashboard.js'


function App() {
  return (
    <>
      <BrowserRouter>
        <CustomAppBar />
        <Switch>
          <Route path="/signin">
            <SignIn />
          </Route>
          <Route path="/signup">
          </Route>
          <Route path="/">
            <Dashboard />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
